@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../theme.scss";


.footer{
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: rgb(224, 224, 224);
    a, a:active, a:focus, a:visited, a:hover{
        color: rgb(27, 27, 27);
        font-family: $custom-text;

    }
    p{
        font-size: 1.1em;
    }
    a{
        font-size: 1.2em;
    }
    a:hover{
        color: rgba(121, 86, 165, 1);

    }
    h6{
        font-family: $custom-header;
        font-size: 1.6em;
    }
}

@include media-breakpoint-up(xs) {
    .footerIcon{
        margin-right: 5px;
    }
}


@include media-breakpoint-up(sm) {
    .footer{
        p{
            font-size: 1.2em;
        }
    }
}


@include media-breakpoint-up(md) {
    .footerIcon{
        margin-right: 5px;
    }
}


@include media-breakpoint-up(lg) {
}


@include media-breakpoint-up(xl) {
}