@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";


@include media-breakpoint-up(xs) {
    .servicesIcon{
        width: 150px;
        height: auto;
    }

    .servicesContent{
        padding: 20px 0 50px 0;
        a{
            // font-family: "Indie Flower", cursive;
            color: #4a2179;
        }
        a:hover{
            // font-family: "Indie Flower", cursive;
            color: #662ca8;
        }
    }
}

@include media-breakpoint-up(sm) {
    .servicesIcon{
        width: 170px;
        height: auto;
    }
}

@include media-breakpoint-up(md) {
    .servicesIcon{
        width: 190px;
        height: auto;
    }
    .servicesContent{
        padding: 40px 0 60px 0;
    }
}

@include media-breakpoint-up(lg) {
    .servicesIcon{
        width: 200px;
        height: auto;
    }
}



