@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";


@include media-breakpoint-up(xs) {
    h6{
        a{
            color: #4a2179;
        }
        a:hover{
            color: #662ca8;
        }
    }
    .faqHeader{
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .faqContent{
        padding-top: 0px;
        padding-bottom: 30px;
    }

    .faqIcon{
        width: 40px;
        height: auto;
    }
    .questionAnswer{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .question{
        padding-bottom: 5px;
        font-weight: bold;
    }

    .answer{
        padding-top: 5px;
        
    }
}

@include media-breakpoint-up(sm) {
    .faqIcon{
        width: 60px;
        height: auto;
    }
}

@include media-breakpoint-up(md) {
    .faqIcon{
        width: 90px;
        height: auto;
    }

    .faqContent{
        padding-top: 15px;
        padding-bottom: 50px;
    }
}

@include media-breakpoint-up(lg) {
    .faqIcon{
        width: 100px;
        height: auto;
    }
}